import deburr from 'lodash/deburr.js';
import type { Coordinate, Estacion, EstacionId } from '@/interfaces';

export const API_VERSION = 'v4';

export const estaciones: Estacion[] = [
  {
    nombre: 'Hualqui',
    id: 11,
    tipo: 'biotren',
    coor: {
      lat: -36.9804951,
      lng: -72.9409602,
    },
    zona: 1,
    linea: 1,
  },
  {
    nombre: 'La Leonera',
    id: 12,
    tipo: 'biotren',
    coor: {
      lat: -36.9514351,
      lng: -73.0131626,
    },
    zona: 2,
    linea: 1,
  },
  {
    nombre: 'Manquimavida',
    id: 13,
    tipo: 'biotren',
    coor: {
      lat: -36.9382302,
      lng: -73.0112314,
    },
    zona: 2,
    linea: 1,
  },
  {
    nombre: 'Pedro Medina',
    id: 14,
    tipo: 'biotren',
    coor: {
      lat: -36.9254689,
      lng: -73.021574,
    },
    zona: 2,
    linea: 1,
  },
  {
    nombre: 'Chiguayante',
    id: 15,
    tipo: 'biotren',
    coor: {
      lat: -36.915176,
      lng: -73.0290842,
    },
    zona: 2,
    linea: 1,
  },
  {
    nombre: 'Concepción',
    id: 16,
    coor: {
      lat: -36.8296658,
      lng: -73.0613351,
    },
    tipo: 'biotren',
    zona: 3,
    linea: 1,
  },
  {
    nombre: 'Lorenzo Arenas',
    id: 17,
    tipo: 'biotren',
    coor: {
      lat: -36.8080606,
      lng: -73.0712754,
    },
    zona: 3,
    linea: 1,
  },
  {
    nombre: 'UTFSM',
    id: 18,
    tipo: 'biotren',
    coor: {
      lat: -36.7836246,
      lng: -73.0855715,
    },
    zona: 4,
    linea: 1,
  },
  {
    nombre: 'Los Condores',
    id: 19,
    tipo: 'biotren',
    coor: {
      lat: -36.7569545,
      lng: -73.0916172,
    },
    zona: 4,
    linea: 1,
  },
  {
    nombre: 'Higueras',
    id: 20,
    tipo: 'biotren',
    coor: {
      lat: -36.7393441,
      lng: -73.1029737,
    },
    zona: 4,
    linea: 1,
  },
  {
    nombre: 'El Arenal',
    id: 21,
    tipo: 'biotren',
    coor: {
      lat: -36.7251566,
      lng: -73.1168944,
    },
    zona: 5,
    linea: 1,
  },
  {
    nombre: 'Mercado Talcahuano',
    id: 22,
    tipo: 'biotren',
    coor: {
      lat: -36.7118243,
      lng: -73.1154433,
    },
    zona: 5,
    linea: 1,
  },
  {
    nombre: 'Juan Pablo II',
    id: 23,
    coor: {
      lat: -36.8385491,
      lng: -73.0974269,
    },
    tipo: 'biotren',
    zona: 6,
    linea: 2,
  },
  {
    nombre: 'Diagonal Biobio',
    id: 24,
    coor: {
      lat: -36.837362,
      lng: -73.1181979,
    },
    tipo: 'biotren',
    zona: 6,
    linea: 2,
  },
  {
    nombre: 'Alborada',
    id: 25,
    coor: {
      lat: -36.8464698,
      lng: -73.1309438,
    },
    tipo: 'biotren',
    zona: 6,
    linea: 2,
  },
  {
    nombre: 'Costa Mar',
    id: 26,
    coor: {
      lat: -36.8549177,
      lng: -73.1330144,
    },
    tipo: 'biotren',
    zona: 7,
    linea: 2,
  },
  {
    nombre: 'El Parque',
    id: 27,
    coor: {
      lat: -36.8675298,
      lng: -73.1360339,
    },
    tipo: 'biotren',
    zona: 7,
    linea: 2,
  },
  {
    nombre: 'Lomas Coloradas',
    id: 28,
    coor: {
      lat: -36.8830705,
      lng: -73.1398273,
    },
    tipo: 'biotren',
    zona: 7,
    linea: 2,
  },
  {
    nombre: 'Cardenal Raúl Silva H.',
    id: 29,
    coor: {
      lat: -36.8982069,
      lng: -73.1434536,
    },
    tipo: 'biotren',
    zona: 7,
    linea: 2,
  },
  {
    nombre: 'Hito Galvarino',
    id: 30,
    coor: {
      lat: -36.9540672,
      lng: -73.1568003,
    },
    tipo: 'biotren',
    zona: 8,
    linea: 2,
  },
  {
    nombre: 'Los Canelos',
    id: 31,
    coor: {
      lat: -36.9799123,
      lng: -73.162744,
    },
    tipo: 'biotren',
    zona: 8,
    linea: 2,
  },
  {
    nombre: 'Huinca',
    id: 32,
    coor: {
      lat: -36.9885939,
      lng: -73.1631088,
    },
    tipo: 'biotren',
    zona: 9,
    linea: 2,
  },
  {
    nombre: 'Cristo Redentor',
    id: 33,
    coor: {
      lat: -36.9989026,
      lng: -73.1626475,
    },
    tipo: 'biotren',
    zona: 9,
    linea: 2,
  },
  {
    nombre: 'Laguna Quiñenco',
    id: 34,
    coor: {
      lat: -37.0124996,
      lng: -73.1581736,
    },
    tipo: 'biotren',
    zona: 10,
    linea: 2,
  },
  {
    nombre: 'Intermodal Coronel',
    id: 35,
    coor: {
      lat: -37.024681,
      lng: -73.1504703,
    },
    tipo: 'biotren',
    zona: 10,
    linea: 2,
  },
].map((est) => Object.assign({}, est, {
  slug: deburr(est.nombre.toLowerCase().replace(/\s/gm, '-').replace(/\./gm, ''))
}));

export const estacionesId: EstacionId = estaciones.reduce(
  (init, estacion) => Object.assign({}, init, { [estacion.id]: estacion }),
  {},
);

export const zonas: string[] = [
  '#38ADD8',
  '#00CDA2',
  '#FF8225',
  '#E88289',
  '#633726',
  '#003D90',
  '#E5C217',
  '#B73948',
  '#21BF21',
  '#7E5AD8',
];

interface MapLayerLayout {
  'line-join': string,
  'line-cap': string,
}
interface MapLayerPaint {
  'line-color': string,
  'line-width': number,
}
interface MapLayer {
  id: string,
  type: string,
  source: any,
  layout: MapLayerLayout,
  paint: MapLayerPaint,
}

export const layer1: MapLayer = {
  id: 'thno-hualqui',
  type: 'line',
  source: {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.11549446994579, -36.7118221757935],
          [-73.1161453291022, -36.71270162675209],
          [-73.1175162498674, -36.71454945816056],
          [-73.11804657292437, -36.71524667010698],
          [-73.11840079660203, -36.71577768905215],
          [-73.11865258660028, -36.71635507873076],
          [-73.11872381521022, -36.71663838285563],
          [-73.11877712617839, -36.71703264930677],
          [-73.1187582374741, -36.71757245047754],
          [-73.11865209523168, -36.71928814544447],
          [-73.11854583888616, -36.72149746087786],
          [-73.11850463232778, -36.72220255567419],
          [-73.11841121537891, -36.72264368303135],
          [-73.11820715554737, -36.72325106860493],
          [-73.11780384185822, -36.72407863645081],
          [-73.11758216959858, -36.7245259421876],
          [-73.11730381501157, -36.72501440054155],
          [-73.1170451191484, -36.72527530413885],
          [-73.11671388263544, -36.72558603766603],
          [-73.11588602105981, -36.72628196453033],
          [-73.11524646211423, -36.72685859467324],
          [-73.11450431673437, -36.7275376451478],
          [-73.11315749830301, -36.72888485256548],
          [-73.1110201230158, -36.7310902400778],
          [-73.11004397265944, -36.73207603873161],
          [-73.10896705020502, -36.73317933356],
          [-73.10678457197474, -36.73533490819958],
          [-73.10541811493836, -36.73674029053916],
          [-73.10335965526997, -36.73882342827439],
          [-73.10159866423301, -36.74060610870225],
          [-73.10050834805776, -36.74180342084947],
          [-73.09942745432325, -36.74322157524325],
          [-73.09874001897124, -36.74436235951148],
          [-73.09770900984336, -36.74611002451684],
          [-73.09614424817214, -36.74885417927332],
          [-73.0947081618968, -36.75135504647214],
          [-73.09359897566935, -36.75332562558997],
          [-73.09219659532418, -36.75580976747965],
          [-73.09107945748013, -36.75777474691782],
          [-73.0897226493444, -36.7601702447643],
          [-73.08869499778046, -36.76192854791111],
          [-73.0879577262157, -36.7632267190556],
          [-73.08709220561983, -36.76473113591317],
          [-73.08670043895641, -36.76554795392146],
          [-73.086330772203, -36.76640427481999],
          [-73.08605917505663, -36.76712748756484],
          [-73.08587261166039, -36.76780559616422],
          [-73.08570568874724, -36.76862012244909],
          [-73.08559698149723, -36.76950480757927],
          [-73.08556792520567, -36.7704643848357],
          [-73.0855758196695, -36.77184502000458],
          [-73.0855895715594, -36.77550851029166],
          [-73.08559625475188, -36.77898999052794],
          [-73.08562769953716, -36.78215012109041],
          [-73.08563670026689, -36.78408409677793],
          [-73.08565147265404, -36.78815355897525],
          [-73.08564639449165, -36.78882677121165],
          [-73.08558731495384, -36.78940211279409],
          [-73.08548072302503, -36.78999974944369],
          [-73.0853643892931, -36.79054245924873],
          [-73.08516078363948, -36.79111390848399],
          [-73.084919867938, -36.79169171020872],
          [-73.08447280983081, -36.79245718263935],
          [-73.08364337380561, -36.793819007579],
          [-73.08247257880336, -36.79574956643767],
          [-73.08143334236699, -36.79749432191471],
          [-73.08012965725044, -36.79963066260029],
          [-73.07871905231544, -36.80193570817964],
          [-73.0779161865258, -36.80325411910769],
          [-73.0774969781089, -36.8038306477175],
          [-73.07703623474389, -36.80436710277695],
          [-73.07642841849912, -36.80487259312402],
          [-73.07566091387213, -36.80538019923054],
          [-73.07365468645155, -36.8066324638715],
          [-73.07121343110906, -36.80817706323656],
          [-73.06947891874243, -36.80926349120501],
          [-73.06878949480739, -36.80971269576079],
          [-73.06828582075698, -36.81004420759465],
          [-73.06782526832224, -36.81041273821141],
          [-73.06748534643458, -36.81074641886888],
          [-73.06731959423762, -36.81095071131066],
          [-73.06702941532849, -36.81136626926508],
          [-73.06674580819134, -36.81187967748273],
          [-73.06658282380292, -36.81231592780422],
          [-73.06649134200785, -36.81270698220395],
          [-73.06643301525608, -36.81317468136668],
          [-73.06642038519821, -36.81349624512176],
          [-73.06646700065268, -36.81475898893176],
          [-73.06652408791193, -36.81625031184718],
          [-73.06656418403014, -36.81744354618067],
          [-73.06657070997737, -36.81780782544097],
          [-73.066545305922, -36.81815046244762],
          [-73.06644371292634, -36.81886655536377],
          [-73.06631287920716, -36.81932870338351],
          [-73.06612916713026, -36.81995828055047],
          [-73.06593036769252, -36.82044173394984],
          [-73.06531207611432, -36.82157838801111],
          [-73.06434249449842, -36.82307478042232],
          [-73.0640896811515, -36.82351014157838],
          [-73.06373160382147, -36.82421201207578],
          [-73.06362482168548, -36.82468942718905],
          [-73.06360917795983, -36.82529903698418],
          [-73.06356714507392, -36.82580853708817],
          [-73.06349416158287, -36.82616786532633],
          [-73.06332315433494, -36.82660867930973],
          [-73.06299730150394, -36.82714232105783],
          [-73.06177437538588, -36.82902803885726],
          [-73.06065458609504, -36.83086980550666],
          [-73.05996571314748, -36.83188930401484],
          [-73.05944315499053, -36.83245535556924],
          [-73.05904475623912, -36.83272495036529],
          [-73.05866652258851, -36.83298935199463],
          [-73.0583118329289, -36.83331031562599],
          [-73.05801353197728, -36.83361192941376],
          [-73.05774869394048, -36.8339358054666],
          [-73.05753021304444, -36.83426296628349],
          [-73.05702441240649, -36.83507485986223],
          [-73.05675113099554, -36.83560107848172],
          [-73.05643064394494, -36.83640636093684],
          [-73.05620654379729, -36.83709392373866],
          [-73.05603387712836, -36.8378588488081],
          [-73.05571596273649, -36.83882729073947],
          [-73.05495066691851, -36.84034038375968],
          [-73.05461468563018, -36.84090263557369],
          [-73.05425644821774, -36.84163984639535],
          [-73.05400272962471, -36.8422815247009],
          [-73.05361989043989, -36.84361003888476],
          [-73.05304842070512, -36.84547511206841],
          [-73.05237771473506, -36.8474883242915],
          [-73.0516515628613, -36.84979893815876],
          [-73.05061146486374, -36.8531979345182],
          [-73.05014108495155, -36.85462916134694],
          [-73.05001770611133, -36.85513016701891],
          [-73.04993027531334, -36.85560382117039],
          [-73.0499235376636, -36.856131788887],
          [-73.04990854548181, -36.85667081102813],
          [-73.04991540517605, -36.85725543999884],
          [-73.0499006487366, -36.85766069524892],
          [-73.04981919448855, -36.85803495680453],
          [-73.04970529610625, -36.85836083627119],
          [-73.04941300707814, -36.85888386226565],
          [-73.04904769987536, -36.85934044210799],
          [-73.048607382883, -36.85983326418885],
          [-73.04782054911125, -36.86048087968411],
          [-73.04716650000402, -36.86102025085612],
          [-73.04660973069045, -36.86151949537541],
          [-73.0462513569, -36.86186822871187],
          [-73.04591483018176, -36.86222422759866],
          [-73.04570518254273, -36.86251929396646],
          [-73.04542797190061, -36.86296942542494],
          [-73.0451181532438, -36.8635132297306],
          [-73.04485676517281, -36.86386592851174],
          [-73.04445348770399, -36.86437827337396],
          [-73.04393450691187, -36.86499212132203],
          [-73.0436604049714, -36.86541703639739],
          [-73.0434444676584, -36.8659166817732],
          [-73.04324820164115, -36.86646617489689],
          [-73.04260527667681, -36.86876314018969],
          [-73.04243645754403, -36.86946166087879],
          [-73.04237281519313, -36.87004553853659],
          [-73.04238057482166, -36.87061423796902],
          [-73.04245809614443, -36.87138527961403],
          [-73.04245252800312, -36.87184925795146],
          [-73.04241335594098, -36.87221233978983],
          [-73.04223799714347, -36.87266712467711],
          [-73.04189869371444, -36.87324369535989],
          [-73.0406935935016, -36.874943124856],
          [-73.04010829173762, -36.8757150588639],
          [-73.0395310166386, -36.87633649498174],
          [-73.03916575278319, -36.87669340225381],
          [-73.03888271180259, -36.87704829406528],
          [-73.03858737006581, -36.87753525991553],
          [-73.03843637043185, -36.87797008107564],
          [-73.03833619278666, -36.87862509894855],
          [-73.03816342424746, -36.88046347308005],
          [-73.03798670229143, -36.88257995930938],
          [-73.03780117149662, -36.88426823027092],
          [-73.0376811775624, -36.88577254002374],
          [-73.03763411170819, -36.88623740087599],
          [-73.0375470288369, -36.88660474229523],
          [-73.0373838310316, -36.88695454395597],
          [-73.03665635160603, -36.88832071331201],
          [-73.03642767147264, -36.88874104121469],
          [-73.03625552006584, -36.88921758045967],
          [-73.03615639288576, -36.88968122720416],
          [-73.03608020880074, -36.89043632265781],
          [-73.03603982966179, -36.89157102755306],
          [-73.0359932941912, -36.89210729701107],
          [-73.03584437007476, -36.89242940517488],
          [-73.03540295191445, -36.89310142512744],
          [-73.03498819977278, -36.89358430309553],
          [-73.03390534499331, -36.89493903848634],
          [-73.03340919204585, -36.89552332027117],
          [-73.03305740653832, -36.89618067379077],
          [-73.03275390257991, -36.89702589882409],
          [-73.03251355670118, -36.89769311861416],
          [-73.03180536158456, -36.89993527318191],
          [-73.03109348959099, -36.9020708484786],
          [-73.03096318993359, -36.90259822256517],
          [-73.03089228832424, -36.90326712369578],
          [-73.0309689679281, -36.90405420513404],
          [-73.03118684322695, -36.90589001184666],
          [-73.03148983016384, -36.90858189934167],
          [-73.03160633865085, -36.9098407869606],
          [-73.03161327823253, -36.91052325990533],
          [-73.03155246249909, -36.91100675805632],
          [-73.03134364164228, -36.91157828656683],
          [-73.0309818824215, -36.91225953078897],
          [-73.02982561948629, -36.91400006994797],
          [-73.02873065811788, -36.91560119496082],
          [-73.02751586053174, -36.91748942606949],
          [-73.02672614685913, -36.91865241922986],
          [-73.02570662962327, -36.9201356717906],
          [-73.02505856248666, -36.92107494552746],
          [-73.0244734941112, -36.92198226055562],
          [-73.023300218585, -36.92367018896549],
          [-73.02287709587851, -36.92422636303353],
          [-73.02234736309184, -36.92483643403721],
          [-73.02136821102177, -36.92581603189845],
          [-73.0202180746037, -36.92704329271933],
          [-73.01880310952754, -36.92849084905833],
          [-73.01781095855243, -36.92951143088968],
          [-73.017230631577, -36.93006481732039],
          [-73.0163683090284, -36.9309732413271],
          [-73.01540631624023, -36.93197051819696],
          [-73.01458048708801, -36.93283984518387],
          [-73.01380051858689, -36.93366631266826],
          [-73.01344951051105, -36.93413572782786],
          [-73.01304275561716, -36.93488316838945],
          [-73.01242275994566, -36.93624878674668],
          [-73.0116329893234, -36.93796954147757],
          [-73.01091458750392, -36.93952099458935],
          [-73.01055969385709, -36.94033676466636],
          [-73.01028628972036, -36.94105914320379],
          [-73.01017602068691, -36.94155722064706],
          [-73.01012999258815, -36.94197994716783],
          [-73.01021286245917, -36.94267705909621],
          [-73.01045951220837, -36.9434754777955],
          [-73.01135691037543, -36.94601807078348],
          [-73.01304024375966, -36.95094714340143],
          [-73.01321323399776, -36.95148880176027],
          [-73.01335422739844, -36.95204592321407],
          [-73.01339465915632, -36.95260207158517],
          [-73.01334448216348, -36.95298960927357],
          [-73.01325912338875, -36.95330405268203],
          [-73.01304379446435, -36.95385737097523],
          [-73.012698842684, -36.95435699371998],
          [-73.01240298615858, -36.95472497193025],
          [-73.01128028175042, -36.95562891121968],
          [-73.01061848323882, -36.9561060995278],
          [-73.0102380453089, -36.95643017427789],
          [-73.00988100150154, -36.95681881736704],
          [-73.00957826124075, -36.95728566730103],
          [-73.00926326949893, -36.95785341493178],
          [-73.00899287738656, -36.95843278892165],
          [-73.00871947199137, -36.95914252033666],
          [-73.00847996579525, -36.95983023001804],
          [-73.00840276218295, -36.96043687846925],
          [-73.008478349476, -36.96095074612673],
          [-73.00859147325045, -36.96163379730493],
          [-73.00864112417473, -36.96212414263417],
          [-73.00858464390252, -36.9626355783241],
          [-73.00844732673255, -36.96297693015529],
          [-73.00815050150274, -36.96341573499409],
          [-73.00754409590311, -36.96402391005164],
          [-73.00696865343127, -36.96437824521641],
          [-73.00612496479701, -36.96466771692673],
          [-73.00499454278075, -36.9649778624111],
          [-73.00425631284094, -36.96519402413505],
          [-73.00329597884779, -36.96534758658673],
          [-73.00204788364314, -36.9655395822036],
          [-72.99969940557081, -36.96601363069797],
          [-72.99727734946734, -36.96665182827567],
          [-72.99612289188958, -36.96709957514125],
          [-72.99517408900734, -36.96738740323816],
          [-72.9944558106844, -36.96766009096655],
          [-72.99357723679239, -36.96782478591006],
          [-72.9924973094946, -36.96800290403485],
          [-72.99132786275486, -36.96833819441589],
          [-72.99021101045646, -36.96869177045495],
          [-72.98879920564605, -36.96902812506213],
          [-72.98651636949214, -36.96942597446548],
          [-72.98554036564688, -36.96959377012293],
          [-72.98447530267433, -36.96960872601476],
          [-72.98343925077127, -36.96971152434386],
          [-72.9797241815199, -36.97025286408262],
          [-72.97780448800431, -36.97024946717426],
          [-72.97625201156382, -36.97025962102924],
          [-72.97475731960377, -36.97024462866033],
          [-72.97335073482057, -36.97000049798473],
          [-72.9716783820897, -36.96964991484434],
          [-72.97020415577555, -36.96911100987773],
          [-72.96861513580639, -36.96846150428888],
          [-72.96753560224342, -36.96806016666248],
          [-72.96665739445262, -36.96783664253815],
          [-72.96524625918516, -36.96771920422921],
          [-72.96286871008527, -36.96754855992964],
          [-72.96201702094758, -36.96732237257871],
          [-72.96017291452003, -36.96670493833392],
          [-72.95904927785517, -36.96624953922984],
          [-72.95812363731756, -36.96573091971459],
          [-72.95668131113045, -36.96492520038323],
          [-72.95588796728008, -36.96462435048343],
          [-72.95509571600623, -36.96449740463029],
          [-72.95406389656324, -36.96455889478985],
          [-72.95317253690463, -36.96486418477073],
          [-72.95245382188052, -36.96529463258347],
          [-72.95168416932869, -36.96611621179713],
          [-72.95105131467025, -36.9667573985608],
          [-72.95015887775261, -36.96733486352381],
          [-72.94912336207243, -36.96776201380899],
          [-72.94667717937388, -36.96866812643197],
          [-72.94550371607029, -36.9691542486414],
          [-72.9446853934176, -36.96967052071184],
          [-72.94386140173422, -36.97043794300251],
          [-72.94348528604932, -36.97099514120858],
          [-72.94309805379879, -36.97205668703274],
          [-72.94250092065916, -36.97406303445405],
          [-72.9422022693192, -36.97583982325724],
          [-72.9421190547407, -36.97709520303575],
          [-72.9420051192427, -36.97776488009791],
          [-72.94184399372925, -36.97832440114005],
          [-72.94155155490394, -36.9791476282483],
          [-72.94101002635011, -36.98064617132025],
        ],
      },
    },
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#888',
    'line-width': 4,
  },
};

export const layer2: MapLayer = {
  id: 'coronel-concepcion',
  type: 'line',
  source: {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [
          [-73.06119579396696, -36.83037013189707],
          [-73.06183637586675, -36.82922674033171],
          [-73.06237732243596, -36.82820380506392],
          [-73.06328757825663, -36.82681771262966],
          [-73.0634006812058, -36.82659741613846],
          [-73.063575763315, -36.82615879133524],
          [-73.0636159391633, -36.82583295996197],
          [-73.06370872498746, -36.82474036314271],
          [-73.06384389296689, -36.82432566664702],
          [-73.06413255849608, -36.8239016154613],
          [-73.0643340589282, -36.82367720459989],
          [-73.06458419962371, -36.82347193636053],
          [-73.06495009870869, -36.82324189317951],
          [-73.06539502907826, -36.82306035082272],
          [-73.06600586947386, -36.82290788002366],
          [-73.06695237135222, -36.82276451884256],
          [-73.06768521779233, -36.82273134005066],
          [-73.06816006130711, -36.82279364067347],
          [-73.06883843930477, -36.82296975022331],
          [-73.07027661460553, -36.82333300125062],
          [-73.07195012851476, -36.82371381288724],
          [-73.07340454645949, -36.8241254583576],
          [-73.07386122399876, -36.82428408248058],
          [-73.07424289272035, -36.82448539193883],
          [-73.07475594644193, -36.82493523787053],
          [-73.08829956587141, -36.83715795335367],
          [-73.0891383041677, -36.83796426832846],
          [-73.08967164923999, -36.83831006991341],
          [-73.09018617468986, -36.83853178351562],
          [-73.09073000043382, -36.8386814999888],
          [-73.09133243110756, -36.83877704460542],
          [-73.09186880844699, -36.83876703623001],
          [-73.09265041755968, -36.83873150598512],
          [-73.1000078996482, -36.83840211523534],
          [-73.1022350206701, -36.83828532636021],
          [-73.10665606053516, -36.83802589262486],
          [-73.11138793999976, -36.83778240542877],
          [-73.11814431905373, -36.8374386721755],
          [-73.11957016933263, -36.83732340716357],
          [-73.12080867408913, -36.83729612687139],
          [-73.12180050976426, -36.83729823886137],
          [-73.12272154500074, -36.83736694276912],
          [-73.1236334725378, -36.83752745125796],
          [-73.12455470685352, -36.83775267783864],
          [-73.12542787524113, -36.8380879210271],
          [-73.12626267077458, -36.8384849624097],
          [-73.12708412637512, -36.83899404558652],
          [-73.12794432163346, -36.83965805959396],
          [-73.12842143120325, -36.84011076669007],
          [-73.12879374826515, -36.84058343522448],
          [-73.12916334796215, -36.84104667629601],
          [-73.1293990145585, -36.84141154225262],
          [-73.12973711065717, -36.84205610866852],
          [-73.12990319244426, -36.84249657892625],
          [-73.13006359607535, -36.8430068579433],
          [-73.1302499481329, -36.8437936432592],
          [-73.13097098104076, -36.84685542300958],
          [-73.13142037076432, -36.84871216920904],
          [-73.13200217953643, -36.85110928561367],
          [-73.13257217195444, -36.8534889949144],
          [-73.13292718949907, -36.85492894410974],
          [-73.13379688508988, -36.85857244200099],
          [-73.1344442559188, -36.86137032248269],
          [-73.13510083094056, -36.86395496353519],
          [-73.13556543285729, -36.86585721296092],
          [-73.13595482234965, -36.86748904109471],
          [-73.13647584814214, -36.86961973619922],
          [-73.13692298602697, -36.87141646922426],
          [-73.13743060049059, -36.87353888555033],
          [-73.13804067152113, -36.87605481455999],
          [-73.13881334602503, -36.87927143480668],
          [-73.1396626052127, -36.88294542635203],
          [-73.13996289493272, -36.88422876514156],
          [-73.14058043025221, -36.88662400161831],
          [-73.14103645784093, -36.88853240325231],
          [-73.14159762949306, -36.89088193319855],
          [-73.14217805727381, -36.89331380564711],
          [-73.14296148027319, -36.89655500825151],
          [-73.14393165011121, -36.90050110048623],
          [-73.1451197473551, -36.90548479260757],
          [-73.14563655803902, -36.9076439973091],
          [-73.1463347339662, -36.91052011714498],
          [-73.14718196565039, -36.91401567123853],
          [-73.14976378243182, -36.92478278810791],
          [-73.15297613667565, -36.93808998682263],
          [-73.15619032072004, -36.95122751177193],
          [-73.15787926608417, -36.95838017034915],
          [-73.16200501279519, -36.97554615572195],
          [-73.16241755613342, -36.97734632202284],
          [-73.16259712071184, -36.97839904074385],
          [-73.16281605291836, -36.97997932473839],
          [-73.16293134195418, -36.98160700216874],
          [-73.1630602337398, -36.9854000874331],
          [-73.16315043607113, -36.98814655611209],
          [-73.1632892655911, -36.9918211122607],
          [-73.16330745434303, -36.99301374123403],
          [-73.16327570492714, -36.99450954561069],
          [-73.16314125412785, -36.99618127454487],
          [-73.16299213831974, -36.99732507161793],
          [-73.16267532810427, -36.99896556779475],
          [-73.16210317919449, -37.00139817430939],
          [-73.16160021367504, -37.00289256005755],
          [-73.16074252953064, -37.00521314761924],
          [-73.15990654564237, -37.00760281469709],
          [-73.15871036262966, -37.01097340279566],
          [-73.15759122531439, -37.01411839357143],
          [-73.1566934643758, -37.01666815136546],
          [-73.15613241412744, -37.01797973682669],
          [-73.1551271556842, -37.02028027983118],
          [-73.15466737424622, -37.0212666273687],
          [-73.15433027967877, -37.02195886531771],
          [-73.15403729025418, -37.02238183256277],
          [-73.15353868357444, -37.02275130699307],
          [-73.15261609095248, -37.02336058668072],
          [-73.15166177098611, -37.02395957804872],
          [-73.15123759817392, -37.02425565727215],
          [-73.15090059295382, -37.02456250305886],
          [-73.15027437200672, -37.02518364761203],
          [-73.14975013246818, -37.02579479676378],
          [-73.1485978408113, -37.02713179209422],
          [-73.14760375286535, -37.02853617252536],
          [-73.1470153939297, -37.0293902023666],
          [-73.14651926807268, -37.03030082073666],
          [-73.14603595585375, -37.03139064853244],
        ],
      },
    },
  },
  layout: {
    'line-join': 'round',
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#888',
    'line-width': 4,
  },
};
