import { computed, ref } from 'vue';
import { usePermission } from '@vueuse/core';
import { defineStore } from 'pinia';
import { estaciones } from '../config';

const EstacionConcepcion = estaciones.find(est => est.id === 16);

const getGeoAsync = (options = {
  enableHighAccuracy: true,
  maximumAge: 30e3,
  timeout: 27e3
}) => {
  return new Promise((res, rej) => navigator.geolocation.getCurrentPosition(
    d => res(d),
    e => rej(e),
    options
  )).catch((e) => {
    return {
      coords: { latitude: Infinity, longitude: Infinity },
      timestamp: 0,
    };
  }) as Promise<Partial<GeolocationPosition>>;
}

export const geoStore = defineStore('geo', () => {
  const geoAccess = usePermission('geolocation');
  const coordsBase = ref({
    latitude: 0,
    longitude: 0,
  });
  const coords = computed(() => ({ 
    lat: coordsBase?.value.latitude || EstacionConcepcion?.coor.lat || 0,
    lng: coordsBase?.value.longitude || EstacionConcepcion?.coor.lng || 0
  }));
  
  const hasGeolocationPermitted = () => {
    return geoAccess.value === 'granted';
  }
  const autoGetGeo = async () => {
    return hasGeolocationPermitted() && getSetGeo();
  }
  const getSetGeo = async () => {
    const geo = await getGeoAsync();

    coordsBase.value = { latitude: geo.coords?.latitude || 0, longitude: geo.coords?.longitude || 0 };

    return coords;
  }

  return {
    coords,
    hasGeolocationPermitted,
    autoGetGeo,
    getSetGeo,
  }
});
